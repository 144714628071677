<template>
  <v-container>
    <div class="article_content">
      <div>
        <h1>Contacter la bige !</h1>
        <p>
          Que ce soit simplement pour prendre un café ou pour obtenir une estimation sur un besoin spécifique,
          <b>nous pouvons nous rencontrer rapidement dans un périmètre de 15Km autour de Lille, Paris et Bruxelles.</b>
          <br />Je me ferais un plaisir de te rencontrer et d'échanger sur ton idée ou ton besoin !
        </p>
        <v-btn color="primary ma-2" to="/contact/create">Prendre rendez-vous !</v-btn>
        <p>
          La prise de rendez-vous arrivera directement dans nos agendas et permet de définir tes attentes dans le but
          de gagner du temps en effectuant une étude préalable et de te rencontrer avec une première documentation.
          <br />
          <br />Si tu ne souhaites pas de rendez-vous ni de réponse rapide,
          <a
            href="mailto:simon@bige.dev"
          >écris moi par email !</a>
          <br />Je suis également présent sur certaines plateformes, en me contactant via linkedin github ou we love dev
          <b>le délai de réponse est d'environ une semaine</b> du lundi au vendredi.
        </p>
        <v-btn color=" ma-2" to="https://github.com/simondelamarre/" target="_blank">Git Hub</v-btn>
        <v-btn
          color=" ma-2"
          to="https://welovedevs.com/app/developer/simon-developpeur-polyglotte-creatif-avec-faculte-dapneiste-pour-sadapter-aux-vieux-si"
          target="_blank"
        >We Love Dev</v-btn>
        <!-- <v-btn color=" ma-2" to="/contact/create">LinkedIn</v-btn> -->
      </div>
    </div>
    <!-- <v-col>
      <v-sheet height="400">
        <v-calendar
          ref="calendar"
          :now="today"
          :value="today"
          :events="events"
          color="primary"
          type="week"
        ></v-calendar>
      </v-sheet>
    </v-col>-->
  </v-container>
</template>

<script>
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: {},
  computed: {
    isMobile() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
        default:
          return false;
      }
    }
  },
  data() {
    return {
      today: "2019-01-08",
      events: [
        {
          name: "Weekly Meeting",
          start: "2019-01-07 09:00",
          end: "2019-01-07 10:00"
        },
        {
          name: "Thomas' Birthday",
          start: "2019-01-10"
        },
        {
          name: "Mash Potatoes",
          start: "2019-01-09 12:30",
          end: "2019-01-09 15:30"
        }
      ]
    };
  },
  methods: {
    handleAnimation: function() {}
  }
};
</script>